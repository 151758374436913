<template>
  <div class="mappings-wrapper">
    <div class="title">
      Mappings:
    </div>
    <div v-for="regexp in v" :key="regexp.regex" class="row">
      <div>
        <Code
          :value="regexp.mapping"
          :label="regexp.regex"
          :language="'plaintext'"
          :auto-height="true"
          :suggestions="extractGroups(regexp.regex)"
          class="editor"
          @input="onInput($event, regexp)"
        />
      </div>
    </div>
    <!-- Your component's HTML template goes here -->
  </div>
</template>

<script>
import Code from '@/components/common/Code';

export default {
  components: {
    Code
  },
  props: {
    regexps: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      v: this.value ? this.value : []
      // Your component's data goes here
    };
  },
  computed: {},
  watch: {
    regexps: {
      handler() {
        this.v = this.regexps.map(regex => {
          const existing = this.v.find(v => v.regex === regex);
          return {
            regex: regex,
            mapping: existing ? existing.mapping : ''
          };
        });
      },
      immediate: true
    }
  },
  methods: {
    extractGroups(regex) {
      return regex
        .toString()
        .match(/<([^>]+)>/g)
        .map(name => name.slice(1, -1))
        .map(name => ({ label: name, documentation: name }));
    },
    onInput(value, regexp) {
      regexp.mapping = value;
      this.$emit('input', this.v);
    }
    // Your component's methods go here
  }
};
</script>

<style scoped>
.title {
  font-weight: 500;
  font-size: 0.75rem;
  letter-spacing: 0.025em;
  margin: 15px 0;
}
.row {
  .preview {
    font-size: 10px;
  }
  .editor {
    height: auto;
  }
}
/* Your component's CSS styles go here */
</style>
