<template>
  <p-edit v-if="$hasPermission('organizations.read')" />
  <p-not-found v-else />
</template>

<script>
import NotFound from './../components/NotFound.vue';
import EditOrganization from './../components/organizations/EditOrganization.vue';

export default {
  components: {
    'p-edit': EditOrganization,
    'p-not-found': NotFound
  }
};
</script>
